export default function LoadingButtonStyles() {
  return {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',

          '&.MuiButton-text': {
            '& .MuiLoadingButton-startIconPendingStart': {
              marginLeft: 0,
            },
            '& .MuiLoadingButton-endIconPendingEnd': {
              marginRight: 0,
            },
          },
        },
      },
    },
  };
}
