import { InputSelectIcon } from './CustomIcons';

export default function SelectStyles() {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
      },
    },
  };
}
