import { ReactNode } from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useLocales } from 'src/hooks/useLocales';

interface ThemeLocalizationProps {
  children: ReactNode;
}

export function ThemeLocalization({ children }: ThemeLocalizationProps) {
  const defaultTheme = useTheme();

  const { currentLang } = useLocales();

  const theme = createTheme(defaultTheme, currentLang.systemValue);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
